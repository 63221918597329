@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400&display=swap');

.taglineText {
    text-align: center;
    font-family: 'Roboto Slab', serif;
    font-size: 25px;
    margin: auto;
    margin-top: -30px;
    display: block;
    color: white
}
.imageContainer {
    margin: auto;
    margin-top: 40px;
    height: 100px;
    width: 100px;
}

.imageContainer img {
    height: auto;
    width: 100%;
}

.pageContainer {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(#8dcc9a, #f4faf6);
}

.loadingCircle {
    margin: auto;
    color: #8dcc9a;
    display: block !important;
}

.headerContainer {
    margin-bottom: 10px;
    display: inline-block;
}

.logoContainer {
    text-align: center;
    align-items: center;
    margin-left: 80px;
    width: 300px;
    height: 80px;
}

.spacingContainer {
    width: 920px;
}

.buttonContainer {
    margin-top: 15px;
    width: 500px;
    height: 50px;
}
.shopLocalDiv {
    position: absolute;
    float: right;
    top: 0;
    right: 10px;
    width: 150px;
}
.shopLocalToggle {
    margin: auto;
    display: block;
}
.searchBarDiv {
    position: relative;
    margin-top: 80px;
}

.afterSearchBarDiv {
    position: relative;
    margin-top: 15px;
}

.companyText {
    font-size: 50px;
    font-weight: normal;
    font-family: 'Titillium Web', sans-serif;
    margin: auto;
    display: block;
    color: white;
}
.socialsText {
    text-align: center;
    font-size: 50px;
    font-weight: normal;
    font-family: 'Roboto Slab', serif;
    margin: auto;
    display: block;
    color: #8dcc9a;
}
.navButton {
    display: inline-block;
    width: 200px;
    font-family: 'Roboto Slab', serif !important;
    color: white !important;
    font-size: 20px !important;
}
.socialsButton {
    display: inline-block;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
}
.missionStatement {
    font-size: 23px;
    margin: auto;
    display: block;
    text-align: center;
    color: gray;
    font-weight: normal;
    margin-top: 85px;
}

.searchBarContainer {
    padding: 2px 4px;
    display: flex;
    align-items: center;
    width: 45vw;
    margin: auto;
}


.searchBarInput {
    margin-left: 20px;
    flex: 1;
}
.iconButton {
    padding: 10px
}

.listingCard {
    width: 320px;
    margin: 20px;
    height: 305px;
    display: inline-block;
    transition: 0.1s;
}
.cardActionArea {
    height: 100%;
}
.cardImage {
    display: block;
    height: 225px;
    min-width: 100%;
    max-width: 100%;
    max-height: 225px;
    min-height: 225px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.cardTextContainer {
    height: 80px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-style: solid;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    border-color: #7dc58c;
    background-color: #7dc58c;
}

.cardMainText {
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
    color: white;
    font-size: 22px;
    margin-left: 20px;
}

.cardSubText {
    font-family: 'Roboto Slab', serif;
    font-weight: medium;
    color: #1f4426;
    font-size: 18px;
    margin-left: 20px;
}

.socialsContainer {
    margin: auto;
    text-align: center;
}

.resultsContainer {
    margin-left: 10vw;
    margin-right: 10vw;
    text-align: left;
}