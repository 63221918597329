@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.LandingPage_taglineText__29Hxl {
    text-align: center;
    font-family: 'Roboto Slab', serif;
    font-size: 25px;
    margin: auto;
    margin-top: -30px;
    display: block;
    color: white
}
.LandingPage_imageContainer__tXb27 {
    margin: auto;
    margin-top: 40px;
    height: 100px;
    width: 100px;
}

.LandingPage_imageContainer__tXb27 img {
    height: auto;
    width: 100%;
}

.LandingPage_pageContainer__2rSMg {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(#8dcc9a, #f4faf6);
}

.LandingPage_loadingCircle__1NixS {
    margin: auto;
    color: #8dcc9a;
    display: block !important;
}

.LandingPage_headerContainer__1DFON {
    margin-bottom: 10px;
    display: inline-block;
}

.LandingPage_logoContainer__19Ibd {
    text-align: center;
    align-items: center;
    margin-left: 80px;
    width: 300px;
    height: 80px;
}

.LandingPage_spacingContainer__1ixwj {
    width: 920px;
}

.LandingPage_buttonContainer__hzP77 {
    margin-top: 15px;
    width: 500px;
    height: 50px;
}
.LandingPage_shopLocalDiv__1sRR3 {
    position: absolute;
    float: right;
    top: 0;
    right: 10px;
    width: 150px;
}
.LandingPage_shopLocalToggle__vPDgg {
    margin: auto;
    display: block;
}
.LandingPage_searchBarDiv__6U2Y8 {
    position: relative;
    margin-top: 80px;
}

.LandingPage_afterSearchBarDiv__3dfKF {
    position: relative;
    margin-top: 15px;
}

.LandingPage_companyText__Uui-K {
    font-size: 50px;
    font-weight: normal;
    font-family: 'Titillium Web', sans-serif;
    margin: auto;
    display: block;
    color: white;
}
.LandingPage_socialsText__2Fi5q {
    text-align: center;
    font-size: 50px;
    font-weight: normal;
    font-family: 'Roboto Slab', serif;
    margin: auto;
    display: block;
    color: #8dcc9a;
}
.LandingPage_navButton__24lrk {
    display: inline-block;
    width: 200px;
    font-family: 'Roboto Slab', serif !important;
    color: white !important;
    font-size: 20px !important;
}
.LandingPage_socialsButton__25nnb {
    display: inline-block;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
}
.LandingPage_missionStatement__28sfR {
    font-size: 23px;
    margin: auto;
    display: block;
    text-align: center;
    color: gray;
    font-weight: normal;
    margin-top: 85px;
}

.LandingPage_searchBarContainer__31nDp {
    padding: 2px 4px;
    display: flex;
    align-items: center;
    width: 45vw;
    margin: auto;
}


.LandingPage_searchBarInput__3dfpy {
    margin-left: 20px;
    flex: 1 1;
}
.LandingPage_iconButton__2RBIb {
    padding: 10px
}

.LandingPage_listingCard__29hrM {
    width: 320px;
    margin: 20px;
    height: 305px;
    display: inline-block;
    transition: 0.1s;
}
.LandingPage_cardActionArea__2Daug {
    height: 100%;
}
.LandingPage_cardImage__31EFY {
    display: block;
    height: 225px;
    min-width: 100%;
    max-width: 100%;
    max-height: 225px;
    min-height: 225px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.LandingPage_cardTextContainer__18FSq {
    height: 80px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-style: solid;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    border-color: #7dc58c;
    background-color: #7dc58c;
}

.LandingPage_cardMainText__3kulD {
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
    color: white;
    font-size: 22px;
    margin-left: 20px;
}

.LandingPage_cardSubText__u8LMR {
    font-family: 'Roboto Slab', serif;
    font-weight: medium;
    color: #1f4426;
    font-size: 18px;
    margin-left: 20px;
}

.LandingPage_socialsContainer__N72p1 {
    margin: auto;
    text-align: center;
}

.LandingPage_resultsContainer__2jw0n {
    margin-left: 10vw;
    margin-right: 10vw;
    text-align: left;
}
.Listing_headerFirstLine__2RkS_ {
    display: flex;
    position: relative;
}
.Listing_companyText__3h8E9 {
    text-align: center;
    font-size: 40px;
    display: inline-block;
    vertical-align: top;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    font-family: 'Titillium Web', sans-serif;
    color: white;
}
.Listing_backButton__1bmF5 {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    left: 15px;
    z-index: 100;
}
.Listing_headerText__2bPov {
    text-align: center;
    font-size: 35px;
    font-family: Georgia, serif;
    color: black;
    font-weight: normal;
}
.Listing_headerSubtext__2yN3D {
    text-align: center;
    font-size: 20px;
    font-family: Georgia, serif;
    color: gray;
    font-weight: normal;
}
.Listing_headerContainer__1gRMC {
    padding-bottom: 5px;
}
.Listing_options__1OTfM {
    width: 100%;
    height: 50px;
}
.Listing_innerOption__34E81 {
    display: inline-block;
    width: 50%;
    height: 100%;
    cursor: pointer;
    text-align: center;
}
.Listing_optionText__2dZL6 {
    vertical-align: middle;
    line-height: 50px;
    font-size: 30px;
    width: 33%;
}

.App_app__1kX79 {
    background-color: #F4FAF6;
    width: 85vw;
    vertical-align: top;
}
.App_appContainer__3jRx1 {
    display: flex;
}
.Chat_chatContainer__1_iWS {
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
}
.Chat_allChatMessages__3wq0e {
    height: 60vh;
    overflow: scroll;
}
.Chat_sendChatButton__1GrRT {
    display: inline-block;
    width: 10%;
    height: 50px;
    float: right;
}
.Chat_chatTextField__bp3uC {
    width: 85%;
    display: inline-block;
    height: 50px;
}
.Chat_loadingCircle__3hdDd {
    margin: auto;
    color: #8dcc9a;
    display: block !important;
}
.Chat_chatBubbleContainer__1-ur_ {
    min-height: 30px;
    overflow: auto;
}
.Chat_chatSpacer__1EGG9 {
    height: 10px;
}
.Chat_chatBubble__AnOBk {
    max-width: 40%;
    background-color: lightgray;
    height:auto;
    overflow: hidden;
    display: block;
    border-radius: 10px;
    min-height: 30px;
}
.Chat_chatBubblePreview__v7HBm {
    max-width: 80%;
    background-color: lightgray;
    height:auto;
    overflow: hidden;
    display: block;
    border-radius: 10px;
    min-height: 30px;
}
.Chat_chatText__F9S8M {
    padding: 0 15px;
    margin: auto;
    vertical-align: middle;
}
.Chat_chatCard__2CA8m {
    width: 400px;
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
    vertical-align: top;
}
.Chat_cardHeader__R7YB7 {
    height: 40px;
    background-color: rgb(104, 200, 142);
    color: white;
    text-align: center;
}
.Chat_chatCardContent__2Qt3U {
    height: 450px;
    overflow: auto;
}
.Chat_chatPerson__1GFX2 {
    padding-top: 10px !important;
}
.Sidebar_container__3YbFW {
    width: 15vw;
    background-color: #F4FAF6 !important;
    height: 100vh;
    vertical-align: top;
}
.Sidebar_innerContainer__15Gcj {
    display: inline-block;
    width: calc(100% - 2px);
    margin-top: 15px;
}
.Sidebar_verticalDivider__1vU79 {
    height: 94%;
    margin-top: 6%;
    background-color: black;
    width: 2px;
    float: right;
    display: inline-block;
}
.Sidebar_titleText__2dsY7 {
    text-align: center;
    font-size: 30px;
    font-family: 'Titillium Web', sans-serif;
}
.Sidebar_searchBarContainer__1KQFS {
    padding: 2px 4px;
    display: flex;
    align-items: center;
    width: 80%;
    margin: auto;
}
.Sidebar_searchBarInput__281o9 {
    margin-left: 20px;
    flex: 1 1;
}
.Sidebar_iconButton__1W9zj {
    padding: 10px;
    float: right;
}
.Sidebar_drawer__27vM4 {
    width: 100%;
    background: #F4FAF6;
}
.Sidebar_drawerPaper__dh49y {
    width: 15vw;
    background: #F4FAF6 !important;
}
.Offer_offerContainer__CaOUl {
    height: 15vh;
    width: 100%;
    margin-left: 100px;
    margin-top: 50px;
    margin-right: 100px;
    border: 1px solid rgba(16, 16, 16, 0.529);
    display: flex;
    flex-wrap: wrap;
}
.Offer_offerImg__XeNsU {
    display: inline-block;
    height: 15vh;
    vertical-align: top;
    width: 15vh;
}
.Offer_offerTextContainer__3gDAJ {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: calc(100% - 15vh);
    height: 100%;
}
.Offer_offerMainText__3NmBT {
    font-size: 3vh;
    font-weight: bold;
    margin-left: 25px;
    margin-top: 50px !important;
}
.Offer_offerSecondaryText__2IZjs {
    font-size: 2vh;
    margin-left: 100px;
    margin-top: 10px;
    color: gray;
    font-weight: bold;
    overflow-wrap: normal;
    display: inline-block;
}

.Offer_offerPriceText__IqGej {
    font-size: 2vh;
    margin-left: 100px;
    color: gray;
    font-weight: bold;
    position: absolute;
    bottom: 75%;
    right: 5%;
    white-space: nowrap;
}

.Offer_offerText__12uCx {
    font-size: 30px;
    margin-left: 50px;
    margin-top: 10px;
    color: gray;
}

.Offer_buttonContainer__TMCf7 {
    position: absolute;
    bottom: 5%;
    right: 5%;
}
.Profile_headerLabel__13Dn1 {
    text-align: center;
    margin-top: 20px;
    font-size: 60px;
}
.Profile_newListing__1CqjK {
    margin: 50px;
    text-align: center;
}

.Profile_newStore__13s2F {
    margin-top: 100px;
    text-align: center;
}

.Profile_listingLabel__2gxvh {
    text-align: center;
    font-size: 20px;
}
.Profile_createField__Q4X5x {
    width: 300px;
}
.Profile_loadingCircle__2bXlk {
    margin: auto;
    color: #8dcc9a;
    display: block !important;
}
.Profile_createForm__1E5iW {
    text-align: center;
    margin-top: 50px;
}

.Profile_options__30Zcd {
    width: 100%;
    height: 50px;
}
.Profile_innerOption__3FYEa {
    display: inline-block;
    width: 25%;
    height: 100%;
    cursor: pointer;
    text-align: center;
}

.Profile_threeInnerOption__2PogI {
    display: inline-block;
    width: 33.33%;
    height: 100%;
    cursor: pointer;
    text-align: center;
}

.Profile_navButton__3iwd5 {
    display: inline-block;
    width: 100%;
    font-size: 20px !important;
}

.Profile_storeButton__1bdAq{
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: 20px !important;
}

.Profile_tab__6lXJ_ {
    margin-top: 20px;
}

.Profile_grid__1ig7F {
    text-align: "center";
}

.Profile_stripeButton__3jimp {
    height: 50px;
}

.Profile_storeContainer__38GIv {
    min-height: 100px;
    margin-left: 10px;
    border-radius: 15px;
    background-color: #ececec;
    width: 39vw;
    display: flex;
    flex-direction: column;
    /*background: linear-gradient(to bottom, #8dcc9a 50%, white 50%);*/
}

.Profile_storeHeaderContainer__1PYOD {
    display: inline-block;
    margin-top: 15px;
}

.Profile_offerContainer__1RB2B {
    display: inline-block;
    margin-top: 10px;
    margin-left: 125px;
}

.Profile_freqContainer__2Z4I_ {
    display: inline-block;
}

.Profile_offerMainText__17KIN {
    font-size: 23px;
    font-weight: bold;
    margin-left: 125px;
}

.Profile_offerSecondaryText__2_H64 {
    font-size: 18px;
    color:black;
    font-weight: normal;
    display: inline-block;
}

.Profile_offerPricingText__ozyR8 {
    font-size: 18px;
    color:black;
    font-weight: normal;
    display: inline-block;
    position: absolute;
    right: 25vw;
}

.Profile_mySubHeader__3JW0n {
    position: inline;
    font-size: 25px;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 10px !important;
}

.Profile_hr__12BLp {
    border: 1px solid gray;
    width: 99.8%;
}

.Profile_offerButton__36yjE {
    height: 50px;
    width: 300px;
    text-align: center;
    border-radius: 10px;
    background: #97d8ae !important;
}

.Profile_offerButton__36yjE:hover {
    background-color: #82be97 !important;
}

.Profile_subContainer__1hEhl {
    height: 200px;
    margin-left: 80px;
    border-left: 15px solid rgba(17, 17, 17, 0.138);
    border-radius: 15px;
    width: 400px;
    display: flex;
    justify-content: center;
    /*background: linear-gradient(to bottom, #8dcc9a 50%, white 50%);*/
}

.Profile_activeSubContainer__1PmcW {
    display: flex;
    flex-direction: column;
    border-style: solid;
    justify-content: center;
    width: 40vw;
    border-color: gray;
    border-width: 1px;
    margin: auto;
}

.Profile_subscriberText__34tEm {
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
}

.Profile_subscriberSecondaryText__2xptA {
    font-size: 25px;
    margin-left: 30px;
    color:black;
    font-weight: normal;
    display: inline; 
}

.Profile_imageContainer__39C2V {
    margin-left: 5px;
    margin-top: 10px;
    width: 100px;
    height: 100px;
    position: absolute;
}

.Profile_imageContainer__39C2V img {
    height: auto;
    width: 100%;
}
