.headerLabel {
    text-align: center;
    margin-top: 20px;
    font-size: 60px;
}
.newListing {
    margin: 50px;
    text-align: center;
}

.newStore {
    margin-top: 100px;
    text-align: center;
}

.listingLabel {
    text-align: center;
    font-size: 20px;
}
.createField {
    width: 300px;
}
.loadingCircle {
    margin: auto;
    color: #8dcc9a;
    display: block !important;
}
.createForm {
    text-align: center;
    margin-top: 50px;
}

.options {
    width: 100%;
    height: 50px;
}
.innerOption {
    display: inline-block;
    width: 25%;
    height: 100%;
    cursor: pointer;
    text-align: center;
}

.threeInnerOption {
    display: inline-block;
    width: 33.33%;
    height: 100%;
    cursor: pointer;
    text-align: center;
}

.navButton {
    display: inline-block;
    width: 100%;
    font-size: 20px !important;
}

.storeButton{
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: 20px !important;
}

.tab {
    margin-top: 20px;
}

.grid {
    text-align: "center";
}

.stripeButton {
    height: 50px;
}

.storeContainer {
    min-height: 100px;
    margin-left: 10px;
    border-radius: 15px;
    background-color: #ececec;
    width: 39vw;
    display: flex;
    flex-direction: column;
    /*background: linear-gradient(to bottom, #8dcc9a 50%, white 50%);*/
}

.storeHeaderContainer {
    display: inline-block;
    margin-top: 15px;
}

.offerContainer {
    display: inline-block;
    margin-top: 10px;
    margin-left: 125px;
}

.freqContainer {
    display: inline-block;
}

.offerMainText {
    font-size: 23px;
    font-weight: bold;
    margin-left: 125px;
}

.offerSecondaryText {
    font-size: 18px;
    color:black;
    font-weight: normal;
    display: inline-block;
}

.offerPricingText {
    font-size: 18px;
    color:black;
    font-weight: normal;
    display: inline-block;
    position: absolute;
    right: 25vw;
}

.mySubHeader {
    position: inline;
    font-size: 25px;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 10px !important;
}

.hr {
    border: 1px solid gray;
    width: 99.8%;
}

.offerButton {
    height: 50px;
    width: 300px;
    text-align: center;
    border-radius: 10px;
    background: #97d8ae !important;
}

.offerButton:hover {
    background-color: #82be97 !important;
}

.subContainer {
    height: 200px;
    margin-left: 80px;
    border-left: 15px solid rgba(17, 17, 17, 0.138);
    border-radius: 15px;
    width: 400px;
    display: flex;
    justify-content: center;
    /*background: linear-gradient(to bottom, #8dcc9a 50%, white 50%);*/
}

.activeSubContainer {
    display: flex;
    flex-direction: column;
    border-style: solid;
    justify-content: center;
    width: 40vw;
    border-color: gray;
    border-width: 1px;
    margin: auto;
}

.subscriberText {
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
}

.subscriberSecondaryText {
    font-size: 25px;
    margin-left: 30px;
    color:black;
    font-weight: normal;
    display: inline; 
}

.imageContainer {
    margin-left: 5px;
    margin-top: 10px;
    width: 100px;
    height: 100px;
    position: absolute;
}

.imageContainer img {
    height: auto;
    width: 100%;
}