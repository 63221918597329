@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@900&display=swap');

.container {
    width: 15vw;
    background-color: #F4FAF6 !important;
    height: 100vh;
    vertical-align: top;
}
.innerContainer {
    display: inline-block;
    width: calc(100% - 2px);
    margin-top: 15px;
}
.verticalDivider {
    height: 94%;
    margin-top: 6%;
    background-color: black;
    width: 2px;
    float: right;
    display: inline-block;
}
.titleText {
    text-align: center;
    font-size: 30px;
    font-family: 'Titillium Web', sans-serif;
}
.searchBarContainer {
    padding: 2px 4px;
    display: flex;
    align-items: center;
    width: 80%;
    margin: auto;
}
.searchBarInput {
    margin-left: 20px;
    flex: 1;
}
.iconButton {
    padding: 10px;
    float: right;
}
.drawer {
    width: 100%;
    background: #F4FAF6;
}
.drawerPaper {
    width: 15vw;
    background: #F4FAF6 !important;
}