@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@900&display=swap');

.headerFirstLine {
    display: flex;
    position: relative;
}
.companyText {
    text-align: center;
    font-size: 40px;
    display: inline-block;
    vertical-align: top;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    font-family: 'Titillium Web', sans-serif;
    color: white;
}
.backButton {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    left: 15px;
    z-index: 100;
}
.headerText {
    text-align: center;
    font-size: 35px;
    font-family: Georgia, serif;
    color: black;
    font-weight: normal;
}
.headerSubtext {
    text-align: center;
    font-size: 20px;
    font-family: Georgia, serif;
    color: gray;
    font-weight: normal;
}
.headerContainer {
    padding-bottom: 5px;
}
.options {
    width: 100%;
    height: 50px;
}
.innerOption {
    display: inline-block;
    width: 50%;
    height: 100%;
    cursor: pointer;
    text-align: center;
}
.optionText {
    vertical-align: middle;
    line-height: 50px;
    font-size: 30px;
    width: 33%;
}
