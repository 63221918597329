.chatContainer {
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
}
.allChatMessages {
    height: 60vh;
    overflow: scroll;
}
.sendChatButton {
    display: inline-block;
    width: 10%;
    height: 50px;
    float: right;
}
.chatTextField {
    width: 85%;
    display: inline-block;
    height: 50px;
}
.loadingCircle {
    margin: auto;
    color: #8dcc9a;
    display: block !important;
}
.chatBubbleContainer {
    min-height: 30px;
    overflow: auto;
}
.chatSpacer {
    height: 10px;
}
.chatBubble {
    max-width: 40%;
    background-color: lightgray;
    height:auto;
    overflow: hidden;
    display: block;
    border-radius: 10px;
    min-height: 30px;
}
.chatBubblePreview {
    max-width: 80%;
    background-color: lightgray;
    height:auto;
    overflow: hidden;
    display: block;
    border-radius: 10px;
    min-height: 30px;
}
.chatText {
    padding: 0 15px;
    margin: auto;
    vertical-align: middle;
}
.chatCard {
    width: 400px;
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
    vertical-align: top;
}
.cardHeader {
    height: 40px;
    background-color: rgb(104, 200, 142);
    color: white;
    text-align: center;
}
.chatCardContent {
    height: 450px;
    overflow: auto;
}
.chatPerson {
    padding-top: 10px !important;
}