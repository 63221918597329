.offerContainer {
    height: 15vh;
    width: 100%;
    margin-left: 100px;
    margin-top: 50px;
    margin-right: 100px;
    border: 1px solid rgba(16, 16, 16, 0.529);
    display: flex;
    flex-wrap: wrap;
}
.offerImg {
    display: inline-block;
    height: 15vh;
    vertical-align: top;
    width: 15vh;
}
.offerTextContainer {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: calc(100% - 15vh);
    height: 100%;
}
.offerMainText {
    font-size: 3vh;
    font-weight: bold;
    margin-left: 25px;
    margin-top: 50px !important;
}
.offerSecondaryText {
    font-size: 2vh;
    margin-left: 100px;
    margin-top: 10px;
    color: gray;
    font-weight: bold;
    overflow-wrap: normal;
    display: inline-block;
}

.offerPriceText {
    font-size: 2vh;
    margin-left: 100px;
    color: gray;
    font-weight: bold;
    position: absolute;
    bottom: 75%;
    right: 5%;
    white-space: nowrap;
}

.offerText {
    font-size: 30px;
    margin-left: 50px;
    margin-top: 10px;
    color: gray;
}

.buttonContainer {
    position: absolute;
    bottom: 5%;
    right: 5%;
}